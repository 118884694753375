<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>办公系统</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-form :model="querys" ref="querys" label-width="70px" class="search-form">
      <el-row>
        <el-form-item label="关键词" prop="keyword">
          <el-col>
            <el-input v-model="querys.keyword" prefix-icon="Search" @change="fetchs" style="max-width: 180px;"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="单据类型" prop="status" label-width="100px">
          <el-col>
            <el-select v-model="status" @change="fetchs" style="min-width: 110px;">
              <el-option label="全部单据" key="0" value="0" />
              <el-option label="待我审批" key="1" value="1" />
              <el-option label="我的审批" key="2" value="2" />
              <el-option label="我的申请" key="3" value="3" />
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="节点名称" prop="nodeName" label-width="100px">
          <el-col>
            <el-input v-model="nodeName" @change="fetchs" style="max-width: 120px;"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="" label-width="20px">
          <el-button type="primary" icon="search" @click="fetchs()">
            搜索
          </el-button>
        </el-form-item>
      </el-row>
    </el-form>

    <static-table class="table" :pager="pager" :columns="querys.columns" :currHandler="currHandler"
      :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler">
      <template v-slot:formNameFmt="scope">
        {{ scope.row.createUserName }}申请的{{ scope.row.formName }}
      </template>
      <template v-slot:status="scope">
        <span v-if="scope.row.status == 0">草稿</span>
        <span style="color:#79bbff" v-else-if="scope.row.status == 10">流转中</span>
        <span style="color:#67c23a" v-else-if="scope.row.status == 50">结束</span>
        <span style="color:#e6a23c" v-else-if="scope.row.status == -10">退回</span>
        <span style="color:#f56c6c" v-else-if="scope.row.status == -50">拒绝</span>
        <span v-else>未知</span>
      </template>
      <template v-slot:toolbar="scope">
        <el-button @click="openPage(scope.row)" link size="small">
          <span>[查看]</span>
        </el-button>
      </template>
    </static-table>
  </el-card>
</template>
    
<script>
import { fetchHistoryInstance } from "@/api/workflow";
import StaticDialog from "@/components/static-dialog.vue";
import StaticTable from "@/components/static-table.vue";
export default {
  name: "flowHistory",
  components: { StaticDialog, StaticTable },
  data() {
    return {
      querys: {
        keyword: "",
        columns: [
          { type: "index", label: "序号" },
          { prop: "flowName", label: "流程名称", sortable: true, width: "110", showOverflowTooltip: true },
          { prop: "billCode", label: "单据编号", width: "145", sortable: true, showOverflowTooltip: true },
          { prop: "formName", label: "单据类型", sortable: true, width: "90", showOverflowTooltip: true },
          { type: "template", prop: "formNameFmt", label: "单据名称", width: "160", showOverflowTooltip: true },
          { type: 'hidden', prop: "formData", label: "表单数据", searchable: true, showOverflowTooltip: true },
          { type: 'hidden', prop: "formUrl", label: "表单Url", showOverflowTooltip: true },
          { prop: "formDesc", label: "申请描述", sortable: true, searchable: true, width: "270", showOverflowTooltip: true },
          { prop: "remark", label: "备注", searchable: true, width: "70", showOverflowTooltip: true },

          { type: "template", prop: "status", label: "状态", width: "65" },
          { prop: "nodeName", label: "当前节点", width: "90", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "operatorName", label: "待办人", sortable: true, searchable: true, width: "80", showOverflowTooltip: true },

          { prop: "fileNumber", label: "附件", width: "50", searchable: true },

          { prop: "createDate", label: "创建时间", width: "150", sortable: true, },
          { prop: "createUserName", label: "创建人", sortable: true, searchable: true, width: "90", },
          { type: "toolbar", label: "操作" },
        ],
      },
      pager: {
        sidx: "id", //默认的排序字段
        sord: "descending",
      },
      currRow: null,
      status: 0,
      nodeName: '',
    };
  },
  mounted() {
    this.fetchs();
  },
  methods: {
    fetchs() {
      //移除多余的参数
      //let { data, records, total, ...query } = { ...this.pager, ...params };
      let { data, records, total, ...search } = {
        ...this.pager,
        ...this.querys,
      };
      // search.filters = [];
      // if (this.querys.keyword)
      //   search.filters.push({
      //     name: "account",
      //     value: "%" + this.querys.keyword + "%",
      //     operate: "like", 5
      //   });
      fetchHistoryInstance(search, this.status, this.nodeName).then((res) => {
        this.pager = { ...res.data };
      });
    },
    currHandler(row) {
      this.currRow = row;
    },
    pageHandler(page) {
      this.pager.page = page;
      this.fetchs();
    },
    sizeHandler(rows) {
      this.pager.rows = rows;
      this.fetchs();
    },
    sortHandler(order) {
      this.pager.sidx = order.prop;
      this.pager.sord = order.order;
      this.fetchs();
    },
    openPage(row) {
      this.$router.push({ path: row.formUrl, query: { id: row.id } });
    },
  },
  created: function () {
    this.querys.keyword = this.$util.getPageCache('keyword') || '';

    this.pager.page = parseInt(this.$util.getPageCache('page') || 1);
    this.pager.rows = parseInt(this.$util.getPageCache('rows') || 10);

    let sidx = this.$util.getPageCache('sidx');
    if (sidx) this.pager.sidx = sidx;
    let sord = this.$util.getPageCache('sord');
    if (sord) this.pager.sord = sord;

    this.status = this.$util.getPageCache('status') || '1';
  },
  watch: {
    pager: function (newValue, oldValue) {
      this.$util.setPageCache('keyword', newValue.keyword);
      if (newValue.page)
        this.$util.setPageCache('page', newValue.page);
      if (newValue.rows)
        this.$util.setPageCache('rows', newValue.rows);
      if (newValue.sidx)
        this.$util.setPageCache('sidx', newValue.sidx);
      if (newValue.sord)
        this.$util.setPageCache('sord', newValue.sord);
    },
    status: function (newValue, oldValue) {
      if (newValue != oldValue)
        this.$util.setPageCache('status', newValue);
    },
  },
};
</script>